.login-section {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .container {
    text-align: center;
  }
  .login-window {
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: white;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    width: 40%;
    padding: 40px;
    margin: auto;
  }
  .login-title {
    margin-bottom: 10px;
  }
  .login-intro-text {
    margin-bottom: 20px;
  }
}

.background-decoration {
  position: absolute;
  z-index: 0;
  height: 40%;
}
.blue-circle {
  top: 0;
  left: 0;
}
.red-circle {
  bottom: 0;
  right: 0;
}

.randstad-logo-login {
  > svg {
    fill: var(--color-light-blue);
  }
}

.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-button-login {
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 24px;
  text-transform: lowercase;
  text-decoration: none !important;
  background-color: var(--color-light-blue);
  border: 2px solid transparent;
  color: white;
  padding: 10px 25px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid var(--color-dark-blue);
    color: var(--color-dark-blue);
  }
}
