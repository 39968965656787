.c-navigation-bottom {
  position: fixed;
  margin: 0;
  padding: 0;
  bottom: 0px;
  left: 0;
  width: 100%;
  border-top: 2px solid var(--color-dark-blue);

  ul {
    &,
    li {
      list-style: none;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    background: var(--color-light);
  }
  button {
    font-size: 16px;
  }
  button,
  a {
    background: var(--color-light);
    border: none;
    box-shadow: 0;
    color: black;
    text-decoration: none;
    padding: 15px 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
