.user-email {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 30px;
}

.current-users {
  margin-bottom: 35px;
}

.c-user-list {
  padding-bottom: 45px;
  min-height: 100vh;
  > .dashboard-intro-section {
    padding: 70px 80px 00px 100px;
    .randstad-logo {
      > svg {
        fill: var(--color-light-blue);
      }
    }
  }
  .user-list-container {
    ul {
      margin: 0 auto;
      padding: 0;
      // margin: auto;
      li {
        list-style: none;
        margin: 10px 0;
      }
    }
  }
  .add-user-email-form {
    width: 100%;
    input {
      width: 100%;
      line-height: 2;
      margin: 20px 0;
      padding: 10px 10px;
      font-family: var(--font-randstad-regular);
      font-size: 16px;
      ::placeholder {
        font-family: var(--font-randstad-regular);
        font-size: 16px;
      }
    }

    button {
      width: 100%;
    }
  }
  .new-user-block {
    margin-top: 40px;
  }
  .user-list {
    max-width: 500px;
    // margin:auto;
    li {
      margin: 10px 0;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 10px;

      background: var(--color-light);
      button {
        background-color: var(--color-light-blue);
        color: white;
        padding: 10px;
        border: 0;
        box-shadow: 0;
      }
    }
  }
}
