.form-section {
  width: 100%;
  background-color: var(--color-light);

  .randstad-icon-form {
    width: 50px;
    position: absolute;
    left: 30px;
    bottom: 50px;
  }

  .container {
    max-width: 1440px;
    margin: 60px 30px;
  }

  .bucket-title {
    color: #101941;
    padding: 10px 0 20px 250px;
  }

  .form-component-container {
    width: 100%;
    display: flex;
  }

  .navigation-panel {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 50px;
    width: 25%;

    .navigation-list-item {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 10px;
    }

    .form-component-go-back {
      margin-top: auto;
      position: absolute;
      top: 20px;
      left: 30px;
      color: #101941;
      display: flex;
      text-decoration: none;

      .arrow-back-icon {
        margin-right: 15px;
      }
    }
    .form-component-go-back:hover {
      text-decoration: underline;
    }
  }
}

.question-panel {
  height: 100%;
  width: 75%;
}

.form-component-question-list {
  padding: 0;
}

.form-component-question-list-item {
  margin-bottom: 30px;
  width: 100%;
}

.answer-list {
  width: 100%;
  height: 100%;
  padding: 0;
}

.answer-list-item {
  position: relative;
  border: 1px solid #dededc;
  color: var(--color-dark-blue);
  background-color: transparent;
  padding: 30px 40px;
  margin-bottom: 20px;
  list-style: none;
  width: 75%;
  text-decoration: none;
  border-radius: 60px;

  cursor: pointer;

  .answer-text {
    text-align: center !important;
    font-size: 20px;
    line-height: 26px;
    text-transform: lowercase;
  }
}

.answered {
  color: white;
}
.answer-list-item:hover:not(.answered) {
  background-color: #dededc !important;
}

.question-name {
  color: #101941;
  margin-bottom: 20px;
}

.navigation-content-item {
  display: flex;
  padding-bottom: 4px;
  height: 35px;
  align-items: center;
  text-transform: lowercase;
  cursor: pointer;
}

.check-mark-icon,
.index {
  width: 30px;
  margin-bottom: 10px;
  font-size: 16px;
}

.add-note-button {
  font-size: 16px;
  font-family: var(--font-randstad-regular);
  position: absolute;
  top: -25px;
  right: 50px;
  padding: 15px 20px;
  border-radius: 50px;
  background-color: white;
  color: lightgray;
  border: none;
  &.exists {
    color: #101941;
  }
}

.add-note-button::after {
  content: "\25BE";
  display: block;
  color: white;
  font-size: 25px;
  position: absolute;
  top: 49px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-down {
  transform: rotate(270deg);
  margin-left: 5px;

  * {
    fill: #101941;
  }
}
.next-button {
  display: flex;
  background-color: transparent;
  border: 2px solid #101941;
  color: #101941;
  margin-left: auto;
  margin-right: 100px;
}
.next-button:hover {
  background-color: #101941;
  border: 2px solid #101941;
  color: white;

  .button-down {
    * {
      fill: white;
    }
  }
}
.next-button:disabled,
.next-button[disabled] {
  border: 1px solid #999999;
  background-color: transparent;
  color: #666666;

  .button-down {
    * {
      fill: #666666;
    }
  }
}
