:root {
  --color-light: #f7f5ef;
  --color-dark-blue: #0f1941;
  --color-icy-blue: #6ca3b4;
  --color-light-blue: #2175d9;
  --color-red: #e74436;
  --color-yellow: #fdb511;
  --color-grey: #d7d7d7;
  --container-max-width: 1200px;
  --font-randstad-regular: "RandstadFont-regular";
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

section > .container {
  max-width: var(--container-max-width);
  margin: auto;
}

section > .hero-container {
  display: flex;
  flex-direction: column;
}

.custom-button {
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 24px;
  text-transform: lowercase;
  text-decoration: none !important;
  background-color: var(--color-dark-blue);
  border: 2px solid transparent;
  color: white;
  padding: 25px 25px;
  border-radius: 4px;
  width: fit-content;
  &:hover {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid var(--color-dark-blue);
    color: var(--color-dark-blue);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: transparent;
    color: #666666;
  }

  &-secondary {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 20px 40px;
    border-radius: 5px;
  }
  &-secondary:hover {
    background-color: rgba(255, 255, 255, 0.219);
  }
}

.app-container {
  background-color: var(--color-primary);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-question-mark {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.content-container {
  background-color: var(--color-light);
  // width: 1500px;
  // height: fit-content;
  min-height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  position: relative;
  width: 1440px;
}

.randstad-logo {
  width: 250px;
  margin-bottom: 80px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: RandstadFont-regular;
  font-weight: 100 !important;
}

h1 {
  font-size: 80px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-light-blue);
  text-transform: lowercase;
  opacity: 1;
  line-height: 104px !important;
}

h2 {
  font-size: 60px;
  color: var(--color-dark-blue);
  text-align: left;
  line-height: 78px !important;
  letter-spacing: 0px;
  text-transform: lowercase;
  opacity: 1;
}
h3 {
  font-size: 40px;
  color: var(--color-dark-blue);
  text-align: left;
  line-height: 52px !important;
  letter-spacing: -0.12px;
  text-transform: lowercase;
  opacity: 1;
}

h4 {
  font-size: 18px;
  color: var(--color-dark-blue);
  text-align: left;
  line-height: 30px !important;
  letter-spacing: 0px;
  text-transform: lowercase;
  opacity: 1;
  color: var(--color-light-blue);
  margin: 20px 0;
}

h5 {
  font-size: 26px !important;
  text-align: left;
  letter-spacing: -0.08px;
  line-height: 34px !important;
  color: var(--color-light-blue);
  text-transform: lowercase;
  opacity: 1;
}

p {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 30px;
}

.hidden {
  display: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.loading-container {
  background-color: #101941;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-message {
  color: white;
}
