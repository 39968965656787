.country-name {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 30px;
}
.country-selected {
  text-decoration: underline;
  color: var(--color-light-blue);
}

.current-country {
  margin-bottom: 35px;
}

.c-country-list {
  padding-bottom: 45px;
  min-height: 100vh;
  > .dashboard-intro-section {
    padding: 70px 80px 00px 100px;
    .randstad-logo {
      > svg {
        fill: var(--color-light-blue);
      }
    }
  }

  .country-list-container {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    ul {
      height: 400px;
      overflow: auto;
      margin: 0 auto;
      padding: 0;
      padding-right: 0px;
      padding-bottom: 75px;

      li {
        list-style: none;
        margin: 10px 0;
        padding-right: 15px;
        cursor: pointer;
      }
      .transparent-element {
        position: absolute;
        bottom: 37px;
        height: 75px;
        width: 100%;
        background-color: var(--color-light);
        background: linear-gradient(180deg, transparent, var(--color-light));
      }
    }
    .arrow-icon-country {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      svg {
        padding-top: 10px;
        width: 40px;
        path {
          fill: var(--color-light-blue);
        }
      }
    }
  }

  .country-list {
    // margin:auto;
    li {
      margin: 10px 0;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 10px;

      background: var(--color-light);
      button {
        background-color: var(--color-light-blue);
        color: white;
        padding: 10px;
        border: 0;
        box-shadow: 0;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        .arrow-next {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 100%;
          }
        }
      }
    }
  }
}
