.chart-container {
  width: 35%;

  .legend-of-chart {
    display: flex;
    padding: 10px;
    justify-content: center;
    margin-top: 20px;

    .legend-item {
      color: white;
      padding: 5px;
      margin: 5px;
      border-radius: 4px;
      display: block;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px;
    }

    .distribution-item {
      background-color: var(--color-dark-blue);
    }
    .data-item {
      background-color: var(--color-light-blue);
    }
    .decisions-item {
      background-color: var(--color-red);
    }
    .organisation-item {
      background-color: var(--color-yellow);
    }
  }
}

.result-chart {
  width: 100%;
  position: relative;
  height: fit-content;
}

.total-score-circle {
  background-color: var(--color-light);
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .total-score-text {
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 52px;
  }
}

.result-chart:hover {
  .total-score-circle {
    width: 100px;
    height: 100px;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
