.instruction-page-container {
}

.help-section {
  width: 100%;
  padding: 40px 0;
}
.faq-block {
  background-color: var(--color-yellow);
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;

  * {
    color: #101941;
  }
}
.faq-title {
  margin-bottom: 60px;
}

.help-question-list {
  * {
    list-style: none;
  }
  > li {
    display: flex;

    padding-bottom: 80px;
  }
  > li:last-of-type {
    padding-bottom: 40px;
  }
  padding: 20px 150px 0 100px;
  margin: 0;

  width: 100%;
}
.help-answer-list {
  margin-left: 40px;
  width: 100%;

  > li {
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }

  h2 {
    margin-bottom: 0;
    margin-right: 40px;
    border: 1px solid red;
  }
  .arrow-down {
    margin-left: auto;
  }
}

.contact-block {
  padding: 80px 100px;
}

.contact-title {
  color: #2275d9;
  margin: 40px 0;
}

.contact-icon {
  position: absolute;
  bottom: 0;
  right: 100px;
  width: 30%;
}

.contact-paragraph {
  width: 40%;
  margin-bottom: 40px;

  p {
    font-size: 16px;
  }
}

.contact-link {
  display: flex;
  text-decoration: none;
  color: #2275d9;

  .mail-icon {
    margin-right: 20px;
  }
}

.contact-link:hover {
  text-decoration: underline;
}

.answer-conatainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .answer-title {
    cursor: pointer;
    text-align: start;
    width: 100%;
  }

  .answer-description {
    display: none;
  }

  .active {
    display: inline;
    margin: 20px 0;
    width: 100%;
  }

  .help-arrow {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-90deg);
    transition: transform 0.2s;
  }
  .active-icon {
    transform: rotate(0deg);
  }
}
