.dashboard-page {
  background-color: var(--color-light);
}
.intro-section {
  background-color: var(--color-dark-blue);
  padding: 70px 80px 70px 100px;

  .randstad-logo-succes {
    svg {
      fill: var(--color-light-blue);
    }
  }

  .hero-title {
    color: white;
  }
  .hero-subtitle {
    color: var(--color-light-blue);
    align-self: flex-end;
  }
}

.result-section-div {
  display: flex;
  height: fit-content;
  padding: 80px 0 20px;

  .succes-title {
    color: var(--color-dark-blue);
    align-self: flex-end;
    margin-bottom: 20px;
    margin-right: 60px;
  }

  .container {
    width: 65%;
    margin-right: 40px;
  }

  .succes-paragraph * {
    color: var(--color-dark-blue);
    width: 60%;
    margin: 80px 0;
  }
}

.score-breakdown-section {
  padding-bottom: 50px;

  .score-breakdown-title {
    color: var(--color-icy-blue);
  }

  .bucket-menu {
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid lightgray;
    width: fit-content;
    margin-bottom: 50px;

    .bucket-item {
      padding: 20px 10px;
      list-style: none;
    }
    .menu-item-score-breakdown {
      text-transform: lowercase;
    }
    .bucket-item:hover {
      cursor: pointer;
    }
    .active {
      border-bottom: 4px solid grey;
    }
  }
}

.maturity-level-section {
  padding-bottom: 100px;

  .maturity-level-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .single-block {
      display: flex;
      margin: 10px 0;
    }

    .maturity-level-title {
      margin-top: 25px;
      width: 18%;
      min-width: 18%;
    }
    .foundational-title {
      color: var(--color-icy-blue);
    }
    .connecting-title {
      color: var(--color-red);
    }
    .integrated-title {
      color: var(--color-yellow);
    }

    .question-collection {
      display: flex;
      flex-wrap: wrap;

      .question-name {
        margin: 5px;
        line-height: 21px;
        text-align: left;
        background-color: var(--color-icy-blue);
        color: white;
        padding: 25px;
        border-radius: 4px;
      }

      .foundational {
        background-color: var(--color-icy-blue);
      }
      .connecting {
        background-color: var(--color-red);
      }
      .integrated {
        background-color: var(--color-yellow);
      }
    }
  }
}

.conclusion-section {
  background-color: var(--color-dark-blue);
  position: relative;
  padding: 100px 0;

  .conclusion-container {
    display: flex;
  }

  .hero-title {
    color: var(--color-icy-blue);
    font-size: 4em;
  }
  .hero-title-conclusion {
    margin-bottom: 20px;
  }

  .intro-text * {
    color: white;
    width: 80%;
  }

  .intro-text-conclusion > p > a {
    color: var(--color-light-blue);
  }

  .text-column {
    width: 50%;
  }
  .flower-icon {
    width: 30%;
    position: absolute;
    bottom: 0;
    right: 100px;
  }
}
