.priorities-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-light);
  padding-bottom: 45px;
}

.priorities-page {
  background-color: #101941;
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  .intro-block {
    margin-bottom: 100px;
    margin-top: 80px;
    .intro-title {
      color: white;
      margin-bottom: 10px;
    }
    .intro-text {
      width: 40%;
      p {
        color: white;
      }
      b {
        color: orange;
        font-weight: 100;
      }
    }
  }
}

.maturity-item {
  border: 1px solid #263052;

  margin: 10px;
  padding: 15px 25px;
  width: fit-content;
  white-space: nowrap;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  .item-name {
    font-size: 16px;
  }
}

.maturity-item:hover {
  background-color: #263052;
  cursor: pointer;
}

.maturity-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
}

.foundational-section {
  display: flex;
  margin-bottom: 50px;

  .foundational-title {
    color: #6ca4b4;
    width: 35%;
  }
}
.connecting-section {
  display: flex;
  margin-bottom: 50px;

  .connecting-title {
    color: #e74537;
    width: 35%;
  }
}

.submit-priority-button {
  margin: 40px 0;
}

.priorities-go-back {
  margin-top: auto;
  position: absolute;
  top: 80px;
  left: 100px;
  color: white;
  display: flex;
  text-decoration: none;

  .arrow-back-icon {
    margin-right: 15px;
    * {
      fill: white;
    }
  }
}
.priorities-go-back:hover {
  color: white;
  text-decoration: underline;
}

.prio-logo {
  position: absolute;
  top: 80px;
  right: 100px;
  width: 160px;
}
