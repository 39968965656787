.instruction-page-container {
  .intro-section {
    background-color: #101941;
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 70px 80px 70px 100px;

    @media screen and (max-width: 900px) {
      padding: 80px 100px;
      .intro-text * {
        width: 40%;
      }
    }
    .hero-title {
      color: white;
    }

    .hero-subtitle {
      color: var(--color-light-blue);
      align-self: flex-end;
    }

    .intro-text * {
      color: white;
      width: 45%;
    }
  }
}

.list-section {
  padding: 70px 80px 70px 100px;

  @media screen and (max-width: 900px) {
    padding: 80px 100px;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    .image-container {
      width: 55%;
      padding: 0 80px;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      svg {
        width: 85%;
        margin-left: 100px;
      }
    }
  }

  .intro-title {
    margin-bottom: 35px;
  }

  .intro-list-item {
    list-style: none;
    width: 100%;
    margin-bottom: 25px;

    h5 {
      margin-bottom: 15px;
    }
  }

  .intro-list-item:not(:last-child) {
    margin-right: 30px;
  }

  .intro-list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    width: 45%;
  }

  .start-button {
    align-self: flex-start;
    margin-top: 20px;
  }
}
