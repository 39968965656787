.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: #1019417e;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-note-modal {
  background-color: #f7f5ef;
  height: fit-content;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 20px 20px;
}

.note-block-title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .note-title {
    font-size: 25px;
    color: var(--color-dark-blue);
    margin-bottom: 20px;
  }
  .close-icon {
    cursor: pointer;
  }
}
.note-input-field {
  width: 100%;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 20px;
  color: #101941;
  font-family: var(--font-randstad-regular);
  font-size: 20px;
}
.note-input-field::placeholder {
  color: lightgray;
  font-family: var(--font-randstad-regular);
  font-size: 20px;
}

.save-button {
  background-color: #2275d9;
  border: 2px solid transparent;
  font-family: var(--font-randstad-regular);
  font-size: 18px;
  color: white;
  padding: 20px 0;
  margin-top: 20px;
  width: 100%;
  border-radius: 4px;
}
.save-button:hover {
  cursor: pointer;
}
