.dashboard-section {
  width: 100%;
  background-color: var(--color-light);
  padding-bottom: 45px;

  .randstad-logo-dashboard {
    > svg {
      fill: var(--color-light-blue);
    }
  }

  .dashboard-intro-section {
    padding: 50px 0;

    @media screen and (max-width: 600px) {
      padding: 0;
    }

    .dashboard-hero-title {
      color: #101941;
    }
    .dashboard-hero-subtitle {
      color: #2275d9;
      align-self: flex-end;
      margin-bottom: 10px;
    }
    .intro-text * {
      color: var(--color-dark-blue);
      width: 40%;
    }
  }
}

.dashboard-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-content: center;

  .bucket-title-dashboard {
    margin-bottom: 20px;
  }
}

.bucket-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  width: 100%;
  height: 100%;
  &-item {
    list-style: none;
    width: 100%;
    > div {
      .progress-indicator {
        font-size: 110px;
        text-align: center;
        letter-spacing: 0px;
        color: #feb52b;
        text-transform: lowercase;
        opacity: 1;
      }
      padding: 80px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: stretch;
        .custom-column {
          width: 100% !important;
        }
      }
    }
  }
}
.bucket-list-item-text {
  color: white;
  width: 100%;
}

.bucket-list-item:nth-of-type(1) {
  background-color: green;
}

.column-1 {
  width: 35%;
}
.column-2 {
  width: 40%;
}
.column-3 {
  width: 15%;
}

.custom-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h5 {
    color: #101941;
  }
}

.help-button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  z-index: 9;

  p {
    color: #2175d9;
    margin-bottom: 0;
  }

  .question-mark-icon {
    margin-left: 5px;
  }
  .close-icon {
    margin-left: 5px;
  }
}

.submit-button {
  align-self: center;
  margin: 40px 0;
}

.custom-button-secondary {
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 24px;
  text-transform: lowercase;
  text-decoration: none !important;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: transparent;
    color: #666666;
  }

  &-secondary {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 20px 40px;
    border-radius: 5px;
  }
  &-secondary:hover {
    background-color: rgba(255, 255, 255, 0.219);
  }
}
